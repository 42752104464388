import React, {useEffect} from 'react';
import {useState} from "react";
import '../Styles/home.css'
import Competition from "./Competition";
import { useNavigate } from "react-router-dom";
import Rules from "./Rules.jsx";
import UserLog from "./UserLog";
import UserlogItem from "./userlogItem";
import Moment from 'moment';
import 'moment/locale/ru';

function Home() {

    let navigate = useNavigate()
    Moment.locale('ru')

    const urlPath = 'https://rusoil.online'
    const[isUserDataLoading, setIsUserDataLoading] = useState(false)
    const[isUserLogsLoading, setIsUserLogsLoading] = useState(false)
    const[Month, setMonth] = useState(Moment(new Date()).format("MMM YYYY"))
    const[CurrentMonthSelected, SetCurrentMonthSelected] = useState(true)
    const[isUserSalaryDataLoading, setIsUserSalaryDataLoading] = useState(false)
    const [userData, SetUserData] = useState({
        ID: '',
        Name: '',
        userPosition: '',
        TotalDebt: 0,
        AZS: '',
        OperatorID: '',
        isOnlyGas: 0,
        SalaryBySales: 1
    });
    const [userSalary, SetUserSalary] = useState({
        Rate: 0.0,
        Litres: 0.0,
        Salary: 0.0,
        Bonus: 0.0,
        Prepayment: 0.0,
        Fees: 0.0,
        Minus: 0.0,
        Debt: 0.0,
        TotalSalary: 0.0
    });
    const [userLogs, SetUserLogs] = useState([
        {Date_Created: '...', Payment_type: '...' , Amount: 0, comment: '...'} // comment maximum 150 chars

    ])

    useEffect(()=>{
        GetUserInfo()
        GetUserStatistic(CurrentMonthSelected)

    }, [userData.Name], CurrentMonthSelected)

    async function GetUserInfo(){

        setIsUserDataLoading(true)

        const requestOptions = {
            method: 'GET',
            mode: 'same-origin',//'cors',
            credentials: "include",
            headers: { 'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='}
        };

        const response = await fetch(`${urlPath}/webapi/secured/getuserinfo`, requestOptions);
        const data = await response.json();
        SetUserData(data)
        setIsUserDataLoading(false)
        console.log(data)

        let element = document.getElementById('messageblock')

        if (data.TotalDebt == 0 || data.TotalDebt == null) {
            element.style.visibility = 'hidden';
        } else {
            element.style.visibility = 'visible';
        }
    };

    async function GetUserStatistic(isCurrentMonth) {
       await GetSalary(isCurrentMonth)
       await GetUserLogs(isCurrentMonth)
    }

    async function SetButtonsState(monthIsCurrent) {

        let previousMonth = document.getElementById('btn11')
        let thisMonth = document.getElementById('btn22')

        previousMonth.style.color = 'gray';
        previousMonth.style.border = 'solid gray';
        thisMonth.style.color = 'gray';
        thisMonth.style.border = 'solid gray';

        if (monthIsCurrent) {
            thisMonth.style.color = 'black';
            thisMonth.style.border = 'solid black';

        } else {
            previousMonth.style.color = 'black';
            previousMonth.style.border = 'solid black';
        }
    }

    async function prevMonthClicked() {
        await SetButtonsState(false)
        await setMonth(Moment(new Date()).subtract(1, 'months').format("MMM YYYY"))
        await SetUserSalary({
            Rate: 0.0,
            Litres: 0.0,
            Salary: 0.0,
            Bonus: 0.0,
            Prepayment: 0.0,
            Fees: 0.0,
            Minus: 0.0,
            Debt: 0.0,
            TotalSalary: 0.0
        })
        await SetUserLogs([
            {Date_Created: '...', Payment_type: '...' , Amount: 0, comment: '...'} // comment maximum 150 chars

        ])
        await SetCurrentMonthSelected(false)
        await GetUserStatistic(false)

    }

    async function currentMonthClicked() {
        await SetButtonsState(true)
        await setMonth(Moment(new Date()).format("MMM YYYY"))
        await SetUserSalary({
            Rate: 0.0,
            Litres: 0.0,
            Salary: 0.0,
            Bonus: 0.0,
            Prepayment: 0.0,
            Fees: 0.0,
            Minus: 0.0,
            Debt: 0.0,
            TotalSalary: 0.0
        })
        await SetUserLogs([
            {Date_Created: '...', Payment_type: '...' , Amount: 0, comment: '...'} // comment maximum 150 chars

        ])
        await SetCurrentMonthSelected(true)
        await GetUserStatistic(true)

    }

    async function GetSalary(isCurrentMonth) {
        try {

            const requestOptions = {
                method: 'GET',
                mode: 'same-origin',//'cors',
                credentials: "include",
                headers: { 'Content-Type': 'application/json',
                    'Accept': 'application/json',
                'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='}
            };

            const response = await fetch(`${urlPath}/webapi/secured/GetSalary?isCurrentMonth=${isCurrentMonth}`, requestOptions);
            if (response.status === 200) {
                let data = await response.json();
                SetUserSalary(data)
                setIsUserSalaryDataLoading(false)
            }

        } catch (err) {
            console.log(err)
        }
    }

    async function GetUserLogs(isCurrentMonth){

        setIsUserLogsLoading(true)
        try {

            const requestOptions = {
                method: 'GET',
                mode: 'same-origin',//'cors',
                credentials: "include",
                headers: { 'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic cnVzb2lsQXBwOnJ1c3NvaWw1NDQ2NTc2'}
            };

            const response = await fetch(`${urlPath}/webapi/secured/getUserLogs?isCurrentMonth=${isCurrentMonth}&manualDate=${false}`, requestOptions);
            if (response.status === 200) {
                var data = await response.json();

                SetUserLogs(data)


                setIsUserLogsLoading(false)
            }

        } catch (err) {
            console.log(err)
        }
    }

    async function SignOut() {
        try {
            const requestOptions = {
                method: 'POST',
                mode: 'same-origin',//'cors',
                credentials: "include",
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA==' }
            };

            const response = await fetch(`${urlPath}/webapi/secured/logout`, requestOptions);

            if (!response.ok) {
                // Handle non-successful response
                console.error('Logout failed:', response.statusText);
                // You might want to redirect even if the response is not okay, depending on your requirements
            } else {
                navigate("/login", { replace: true });
            }
        } catch (err) {
            console.error('Error during logout:', err);
            // Handle unexpected errors
        }
    }


    return (
        <div className='Home'>
            <b id='rusoil'>
            RUSOIL
        </b>
            <div onLoadStart={GetUserInfo} className='maindata'>
                <Competition/>
                <div id={'exiteblock'}> <button onClick={SignOut} id={'exiteBtn'}>Выйти</button></div>

                <div className='employeeData'>
                    <div id='employeeblock'>
                        <div className='employeestatic'>
                         Сотрудник:
                        </div>
                        <div className='employeeLabels'> {userData.Name}</div>
                    </div>
                    <div id='positionblock'>
                        <div className='employeestatic'>
                            Должность:
                        </div>
                        <div className='employeeLabels' >{userData.userPosition} </div>
                    </div>
                    <div id='rateblock'>
                        <div className='employeestatic'>
                            Ставка:
                        </div>
                        <div id='ratelabel'>{userSalary.Rate} ₽/л </div>

                    </div>
                    <div id='messageblock'>
                        <div id={'debtmessage'}>Долг за пред. месяц:</div>
                       <div id={'debtValue'}>{Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userData.TotalDebt)}</div>
                    </div>

                </div>

                <div className='salaryData'>

                    <div className='datepicker'>

                        <div id='previousMonth'>
                            <button onClick={prevMonthClicked} type='button'  className='btn01' id='btn11' >Прошлый месяц {}</button>
                        </div>
                        <div id='thisMonth'>
                            <button onClick={currentMonthClicked} type='button'  className='btn02' id='btn22'>Текущий месяц {}</button>

                        </div>

                    </div>

                    <div id = 'month'>{Month}</div>

                    <div className='mainSalaryData'>

                        <div className='SalaryDataBlockStatic'>
                            <div className='mainSalaryStatic'>Литров продано:</div>
                            <div className='litrsSalaryBonusLabels'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'decimal', minimumFractionDigits: 0 }).format(userSalary.Litres)}</div>
                        </div>

                        <div className='SalaryDataBlockStatic'>
                            <div className='mainSalaryStatic'>Зарплата:</div>
                            <div className='litrsSalaryBonusLabels'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Salary)}</div>
                        </div>

                        <div className='SalaryDataBlockStatic'>
                            <div className='mainSalaryStatic'>Премия:</div>
                            <div className='litrsSalaryBonusLabels'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Bonus)}</div>
                        </div>

                        <div id='prepaymentblock'>
                            <div className='mainSalaryStatic'>Авансы:</div>
                            <div id='prepaymentlabel'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Prepayment)}</div>
                        </div>

                        <div className='feeAndMinusBlock'>
                            <div className='mainSalaryStatic'>Штрафы:</div>
                            <div className='feeAndMinuslabels'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Fees)}</div>
                        </div>

                        <div className='feeAndMinusBlock'>
                            <div className='mainSalaryStatic'>Минусы:</div>
                            <div className='feeAndMinuslabels'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Minus)}</div>
                        </div>

                        <div id='debtblock'>
                            <div className='mainSalaryStatic'>Задолжность:</div>
                            <div id='debtlabel'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Debt)}</div>
                        </div>

                        <div id='totalSalaryblock'>
                            <div id='totalSalary'>Итого:</div>
                            <div id='totalSalarylabel'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.TotalSalary)}</div>
                        </div>

                    </div>


                </div>
                {isUserDataLoading
                    ? <div>Загрузка ... </div>
                    : <Rules userPosition = {userData.userPosition}/>
                }

                <div id={'UserLogsTitle'}>Записи за месяц:</div>

                {isUserDataLoading
                    ? <div>Загрузка ... </div>
                    : userLogs.map((Log, index)=>  <UserlogItem data = {Log} key = {index} />)
                }
            </div>




        </div>

    );
};


export default Home;
