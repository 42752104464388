import React, { useState, useEffect } from 'react';
import './Styles/Kofegor_admin.css'; // Import your CSS file for styling
import { useNavigate } from "react-router-dom";

const Kofegor_admin = () => {
    const [competitionTitle, setCompetitionTitle] = useState('');
    const [competitionPrize, setCompetitionPrize] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const [scoreAmount, setScoreAmount] = useState(0);
    const [dayInMonth, setDayInMonth] = useState(0);
    const [selectedCompetition, setSelectedCompetition] = useState(null);
    const [competitions, setCompetitions] = useState([]);
    const [competitionLogs, setCompetitionLogs] = useState([]);
    const url = 'https://rusoil.online'

    let navigate = useNavigate()


    // Function to fetch the list of competitions
    const fetchCompetitions = async () => {
        try {
            const response = await fetch(`${url}/webapi/secured/kofegor_admin/getListCompetitions`, {
                headers: {
                    'Authorization': `Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA==`
                }
            });
            if (response.ok) {
                const data = await response.json();
                console.log('Fetched Competitions:', data);
                setCompetitions(data);
            } else {
                console.error('Failed to fetch competitions');
            }
        } catch (error) {
            console.error('Error fetching competitions', error);
        }
    };

    // Function to fetch logs for a selected competition
    const fetchLogsForCompetition = async (competitionID) => {
        try {
            const response = await fetch(`${url}/webapi/secured/kofegor_admin/getLogsForCompetition?Competition_ID=${competitionID}`, {
                headers: {
                    'Authorization': `Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA==`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setCompetitionLogs(data);
                console.log(data);

            } else {
                console.error('Failed to fetch logs');
            }
        } catch (error) {
            console.error('Error fetching logs', error);
        }
    };

    // Function to create a new competition
    const createCompetition = async () => {
        try {
            // Send a POST request to create a new competition with title and prize
            const response = await fetch(`${url}/webapi/secured/kofegor_admin/createCompetition`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='},
                body: JSON.stringify({ Competition_title: competitionTitle, Competition_prize: competitionPrize }),
            });

            if (response.ok) {
                fetchCompetitions();
                setCompetitionTitle('');
                setCompetitionPrize('');
            } else {
                // Handle error, e.g., show an error message
                console.error('Failed to create competition');
            }
        } catch (error) {
            console.error('Error creating competition', error);
        }
    };

// Function to create a new competition log
    const createCompetitionLog = async () => {
        if (selectedCompetition === null) {
            // Handle the case when no competition is selected
            return;
        }

        try {
            // Send a POST request to create a new competition log with competition ID, employee name, score amount, and day in month
            const response = await fetch(`${url}/webapi/secured/kofegor_admin/createCompetitionLog`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='},
                body: JSON.stringify({
                    Competition_ID: selectedCompetition.Competition_ID,
                    Employee_name: employeeName,
                    Score_Amount: scoreAmount,
                    Day_in_Month: dayInMonth,
                }),
            });

            if (response.ok) {
                // Competition log created successfully
                // Reset form fields
                setEmployeeName('');
                setScoreAmount(0);
                setDayInMonth(0);
                fetchLogsForCompetition(selectedCompetition.Competition_ID);
            } else {
                // Handle error, e.g., show an error message
                console.error('Failed to create competition log');
            }
        } catch (error) {
            console.error('Error creating competition log', error);
        }
    };

// Function to reset logs for the selected competition
    const closeCompetition = async () => {
        if (selectedCompetition === null) {
            // Handle the case when no competition is selected
            return;
        }

        try {
            // Send a POST request to reset logs for the selected competition
            const response = await fetch(`${url}/webapi/secured/kofegor_admin/CloseCompetition?Competition_ID=${selectedCompetition.Competition_ID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='},

            });

            if (response.ok) {
                // Logs reset successfully
                // Refresh the list of logs for the selected competition
                fetchLogsForCompetition(selectedCompetition.Competition_ID);
            } else {
                // Handle error, e.g., show an error message
                console.error('Failed to reset logs');
            }
        } catch (error) {
            console.error('Error resetting logs', error);
        }
    };

    useEffect(() => {
        fetchCompetitions();
    }, []);

    // Function to delete a competition
    const deleteCompetition = async (competitionID) => {
        try {
            const response = await fetch(`${url}/webapi/secured/kofegor_admin/deleteCompetition?Competition_ID=${competitionID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='},

            });

            console.log(competitionID)
            if (response.ok) {
                fetchCompetitions(); // Refresh the list of competitions after deletion
            } else {
                console.error('Failed to delete competition');
            }
        } catch (error) {
            console.error('Error deleting competition', error);
        }
    };

    // Function to delete a competition log
    const deleteLog = async (logID) => {
        try {
            const response = await fetch(`${url}/webapi/secured/kofegor_admin/deleteLog?logID=${logID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='},

            });
            if (response.ok) {
                fetchLogsForCompetition(selectedCompetition.Competition_ID); // Refresh logs for the selected competition after deletion
            } else {
                console.error('Failed to delete log');
            }
        } catch (error) {
            console.error('Error deleting log', error);
        }
    };

    async function SignOut() {
        try {
            const requestOptions = {
                method: 'POST',
                mode: 'same-origin',//'cors',
                credentials: "include",
                headers: { 'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA==' }
            };

            const response = await fetch(`${url}/webapi/secured/logout`, requestOptions);

            if (!response.ok) {
                // Handle non-successful response
                console.error('Logout failed:', response.statusText);
                // You might want to redirect even if the response is not okay, depending on your requirements
            } else {
                navigate("/login", { replace: true });
            }
        } catch (err) {
            console.error('Error during logout:', err);
            // Handle unexpected errors
        }
    }

    return (
        <div className="admin-container">
            <div className={"top_Section"}>
                <h1 className="admin-title">ПАНЕЛЬ АДМИНА</h1>
                <button
                    className="sign_out_button"
                    onClick={SignOut}
                >
                    Выйти
                </button>
            </div>

            <div className="admin-section">
                <h2>Создать новое соревнование</h2>
                <div className="input-group">
                    <input
                        type="text"
                        placeholder="Называние соревнования"
                        value={competitionTitle}
                        onChange={(e) => setCompetitionTitle(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Приз за победу"
                        value={competitionPrize}
                        onChange={(e) => setCompetitionPrize(e.target.value)}
                    />
                    <button
                        className="admin-button"
                        onClick={createCompetition}
                        disabled={!competitionTitle || !competitionPrize} // Disable if either input is empty
                    >
                        Создать
                    </button>
                </div>
            </div>

            <div className="admin-section">
                <h2>Выбрать Соревнование</h2>
                <select
                    id={'drop_down_list'}
                    value={selectedCompetition ? selectedCompetition.Competition_ID : ''}
                    onChange={(e) => {
                        var selectedIndex = e.target.selectedIndex;
                        selectedIndex = selectedIndex - 1
                        if (selectedIndex >= 0 && selectedIndex < competitions.length) {
                            const selectedID = competitions[selectedIndex].Competition_ID;
                            const selected = competitions.find((c) => c.Competition_ID === selectedID);

                            console.log('Selected ID:', selectedID);
                            console.log('Selected Competition:', selected);

                            setSelectedCompetition(selected || {}); // Use an empty object if selected is undefined
                            fetchLogsForCompetition(selectedID);
                        }
                    }}
                >
                    <option value="">Выбери Соревнование</option>
                    {competitions.map((comp) => (
                        <option key={comp.Competition_ID} value={comp.Competition_ID}>
                            {comp.Competition_title}
                        </option>
                    ))}
                </select>
                {selectedCompetition && (
                    <div id={'create_close_div'}>
                        <button
                            id={'create_btn'}

                            className="admin-button"
                            onClick={() => deleteCompetition(selectedCompetition.Competition_ID)}
                        >
                            Удалить соревнование
                        </button>
                        <button
                            id={'create_close_btn'}
                            className="admin-button"
                            onClick={() => closeCompetition(selectedCompetition.Competition_ID)}
                        >
                            Закрыть соревнование
                        </button>
                    </div>
                )}
            </div>
            <div className="admin-section">
                <h2>Добавить запись к соревнованию</h2>
                <div className="input-group">
                    <input
                        type="text"
                        placeholder="Фамилия Имя"
                        value={employeeName}
                        onChange={(e) => setEmployeeName(e.target.value)}
                        disabled={!selectedCompetition} // Disable the input if no competition is selected
                        className={selectedCompetition ? '' : 'disabled-input'} // Add a class for conditional styling
                    />
                    <input
                        type="number"
                        placeholder="балл"
                        value={scoreAmount === 0 ? '' : scoreAmount}
                        onChange={(e) => setScoreAmount(e.target.value)}
                        disabled={!selectedCompetition} // Disable the input if no competition is selected
                        className={selectedCompetition ? '' : 'disabled-input'} // Add a class for conditional styling
                    />
                    <input
                        type="number"
                        placeholder="день"
                        value={dayInMonth === 0 ? '' : dayInMonth}
                        onChange={(e) => setDayInMonth(e.target.value)}
                        disabled={!selectedCompetition} // Disable the input if no competition is selected
                        className={selectedCompetition ? '' : 'disabled-input'} // Add a class for conditional styling
                    />
                    <button
                        className="admin-button"
                        onClick={createCompetitionLog}
                        disabled={!selectedCompetition} // Disable the button if no competition is selected
                    >
                        Добавить
                    </button>
                </div>
            </div>

            <div className="admin-section">
                <h2>Данные о соревновании</h2>
                {selectedCompetition && (
                    <table className="admin-table">
                        <thead>
                        <tr>
                            <th>Имя</th>
                            <th>Балл</th>
                            <th>День в месяце</th>
                            <th>Дейсвтие</th>
                        </tr>
                        </thead>
                        <tbody>
                        {competitionLogs.slice().reverse().map((log, index) => (
                            <tr key={index}>
                                <td>{log.Employee_name}</td>
                                <td>{log.Score_Amount}</td>
                                <td>{log.Day_in_Month}</td>
                                <td>
                                    <button
                                        className="admin-button_log"
                                        onClick={() => deleteLog(log.ID)}
                                    >
                                        Удалить запись
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>

        </div>
    );
};

export default Kofegor_admin;
