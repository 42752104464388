import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route,} from "react-router-dom";
import Login from "./components/Login.jsx";
import Home from "./components/Home.jsx";
import KofegroPage from "./KofegroPage";
import Kofegor_admin from "./Kofegor_admin";

const root = ReactDOM.createRoot(
    document.getElementById("root")
);

root.render(
    <BrowserRouter basename={"/"}>
        <Routes >
            <Route path="/" element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="kofegor911" element={<KofegroPage />} />
            <Route path="kofegor911/admin" element={<Kofegor_admin />} />

        </Routes>
    </BrowserRouter>
);


