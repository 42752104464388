import React, { useState, useEffect } from 'react';
import './Styles/KofegorPage.css'; // Import your CSS file for styling
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const KofegorPage = () => {
    const [competitionsData, setCompetitionsData] = useState([]);
    const [currentCompetitionIndex, setCurrentCompetitionIndex] = useState(0);
    const url = 'https://rusoil.online'

    // Function to fetch data from the server
    const fetchData = async () => {
        try {
            const response = await fetch(`${url}/webapi/public/kofegor911/getCompetitions`);
            if (response.ok) {
                const data = await response.json();
                setCompetitionsData(data);
            } else {
                console.error('Failed to fetch competitions data');
            }
        } catch (error) {
            console.error('Error fetching competitions data', error);
        }
    };

    // Fetch data once when the component mounts
    useEffect(() => {
        fetchData();

        // Set up an interval to fetch data every 10 minutes (600000 milliseconds)
        const intervalId = setInterval(fetchData, 600000);

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    // Function to automatically switch to the next competition


    // Automatically switch to the next competition every 30 seconds
    useEffect(() => {

        const switchToNextCompetition = () => {
            setCurrentCompetitionIndex((prevIndex) => (prevIndex + 1) % competitionsData.length);
        };

        const intervalId = setInterval(switchToNextCompetition, 30000); // 30 seconds

        return () => {
            clearInterval(intervalId);
        };
    }, [competitionsData]);

    if (competitionsData.length === 0) {
        return <div>Loading...</div>;
    }

    const currentCompetition = competitionsData[currentCompetitionIndex];
    const employeeNames = currentCompetition.logs.map((log) => log.Employee_name);
    const uniqueEmployeeNames = Array.from(new Set(employeeNames));


    // Calculate the average amount for each employee
    const averageAmounts = uniqueEmployeeNames.map((employeeName) => {
        const employeeLogs = currentCompetition.logs.filter((log) => log.Employee_name === employeeName);
        const totalAmount = employeeLogs.reduce((sum, log) => sum + log.Score_Amount, 0);
        const daysWorked = new Set(employeeLogs.map((log) => log.Day_in_Month)).size;
        return daysWorked === 0 ? 0 : Math.round(totalAmount / daysWorked);
    });

// Calculate the amounts by day for each employee
    const amountsByDay = {};
    currentCompetition.logs.forEach((log) => {
        if (!amountsByDay[log.Day_in_Month]) {
            amountsByDay[log.Day_in_Month] = {};
        }
        if (!amountsByDay[log.Day_in_Month][log.Employee_name]) {
            amountsByDay[log.Day_in_Month][log.Employee_name] = 0;
        }
        amountsByDay[log.Day_in_Month][log.Employee_name] += log.Score_Amount;
    });


    // Find the employee with the highest average amount
    const maxAverageAmount = Math.max(...averageAmounts);
    const highestAverageEmployeeIndex = averageAmounts.indexOf(maxAverageAmount);

    // Sort employees by average amount (descending)
    const sortedEmployees = uniqueEmployeeNames
        .map((employeeName, index) => ({
            name: employeeName,
            average: averageAmounts[index],
            order: index + 1,
        }))
        .sort((a, b) => b.average - a.average);

    // Reverse the order of the second table
    const reversedAmountsByDay = {};
    Object.entries(amountsByDay).forEach(([day, amounts]) => {
        reversedAmountsByDay[day] = {};
        uniqueEmployeeNames.forEach((employeeName) => {
            reversedAmountsByDay[day][employeeName] = amounts[employeeName] || 0;
        });
    });

    const reversedDays = Object.keys(reversedAmountsByDay).sort((a, b) => b - a);


    return (
        <div className={"MainDiv"}>
            <h1 id={"competition_main_title"}>СОРЕВНОВАНИЯ КОФЕГОР</h1>
            <div className="competition-card-container">
                <TransitionGroup>
                    <CSSTransition
                        key={currentCompetition.Competition_ID}
                        timeout={2000} // Animation duration in milliseconds
                        classNames="competition-card"
                    >
                        <div className="competition-card">
                            <div className="competition-title">
                                <h1 id={"goalTitle"}>Задание: {currentCompetition.Competition_title}</h1>
                                <h1 id={"prizeTitle"}>Приз: {currentCompetition.Competition_prize}</h1>
                            </div>

                            <div className="competition-card-tab">
                                <h2 className='tableTitle'>РЕЙТИГ</h2>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Место</th>
                                        <th>Сотрудник</th>
                                        <th>Средний балл</th>
                                    </tr>
                                    </thead>
                                    <tbody id='tbody1'>
                                    {sortedEmployees.map((employee, index) => (
                                        <tr key={employee.name}>
                                            <td>{index + 1}</td>
                                            <td
                                                style={{
                                                    fontWeight: index === highestAverageEmployeeIndex ? 'bold' : 'normal',
                                                }}
                                            >
                                                {employee.name}
                                            </td>
                                            <td>{employee.average}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="competition-card-tab">
                                <h2 className='tableTitle'>ПО ДНЯМ</h2>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>День</th>
                                        {uniqueEmployeeNames.map((employeeName, index) => (
                                            <th key={index}>{employeeName}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody id="tbody2">
                                    {reversedDays.map((day) => (
                                        <tr key={day}>
                                            <td>{day}</td>
                                            {uniqueEmployeeNames.map((employeeName, index) => (
                                                <td key={index}>{reversedAmountsByDay[day][employeeName] || 0}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </div>
    );
};

export default KofegorPage;
