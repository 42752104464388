import React from 'react';

const OperRules = () => {
    return (
        <div>

            <h3>Правила Операторов:</h3>
            <p10>В процессе заправки машины помощник оператора должен находится рядом с машиной.</p10>
            <div className={'fee'}>Штраф: 500₽</div>

            <p>В 06:00 Оператор должен проснуться и до конца смены не спать.</p>
            <div className={'fee'}>Штраф: 500₽</div>

            <p>Нельзя пропускать машины. Помощник оператора обязан заправить каждую машину лично.</p>
            <div className={'fee'}>За каждую пропущенную машину штраф 100₽</div>

            <p>Нельзя грубить клиентам.</p>
            <div className={'fee'}>Штраф: снижение ставки от продаж..</div>

        </div>
    );
};

export default OperRules;