import React from 'react';

const PomOperRules = () => {
    return (
        <div>
            <h3>Правила Помощ. Операторов:</h3>
            <p>В процессе заправки машины помощник оператора должен находится рядом с машиной.</p>
            <div className={'fee'}>Штраф: 100₽ за повторное нарушение 200₽</div>

            <p>В 06:00 Помощник оператора должен проснуться и до конца смены не спать.</p>
            <div className={'fee'}>Штраф: 500₽</div>

            <p>Нельзя пропускать машины. Помощник оператора обязан заправить каждую машину лично.</p>
            <div className={'fee'}>За каждую пропущенную машину штраф 100₽</div>

            <p>Нельзя грубить клиентам.</p>
            <div className={'fee'}>Штраф: снижение ставки от продаж на 3 копейки с литра..</div>

        </div>
    );
};

export default PomOperRules;