import React, {useEffect} from 'react';
import {useState} from "react";
import '../Styles/Rules.css'
import PomOperRules from "./PomOperRules";
import OperRules from "./OperRules";

const Rules = (props) => {

    const [showrules, setShowrules] = useState(true)
    const [IsuserOper, setIsUserOper] = useState(false)


useEffect(()=>{
    
    if (props.userPosition === 'Оператор') {
        setIsUserOper(true)
    } else {
        setIsUserOper(false)
    }
},[props])

    function changeBtnImage()
    {

        let id = "RulesAndFeesbtn2";

        if(!showrules)
        {
            let element = document.getElementById(id)

            element.style.transform= 'scale(-1)';

            let element2 = document.getElementById('RulesBlockID')


            element2.style.visibility = 'visible';
            element2.style.display = "block";


            setShowrules(true)
        }
        else
        {
            let element = document.getElementById(id)

            element.style.transform = 'scale(1)';

            let element2 = document.getElementById('RulesBlockID')
            element2.style.visibility = 'hidden';
            element2.style.display = "none";

            setShowrules(false)

        }
    }

    return (
        <div>
            <div className={'RulesAndFees'}>
                <button id='RulesAndFeesbtn' onClick={changeBtnImage}>Правила и Штрафы</button>
                <button id='RulesAndFeesbtn2' onClick={changeBtnImage}></button>

            </div>

            <div id={'RulesBlockID'}>

                {IsuserOper
                    ? <OperRules/>
                    : <PomOperRules/>
                }

            </div>
        </div>
    );
};

export default Rules;